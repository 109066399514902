import { Component } from '@angular/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent {
  public graphURL = 'https://us-east-1.graphassets.com/AhiTLfTgQg6aq6zPgJZ6fz//';

  public ourImage = `${this.graphURL}b9pTVrxStimqEatHoilB`;
  public logoOCC = `${this.graphURL}ZIKwyqXRxiGtMNCw5RPF`
  public logoLinkedin = `${this.graphURL}yWPQ1IgDTZSJRCLcLeIf`

  constructor() { }
}
